<template>
  <div id="divNotificationsWrapper">
    <div class="content-row border-bottom">
      <div class="content-layout-fixer">
        <div class="top-content-wrapper">
          <div>
            <div class="top-content-actions">
              <ul class="actions">
                <li @click="activeList = 'private'">
                  <a :class="activeList !== 'private' ? 'notActive' : 'active'">
                    <VueText sizeLevel="4" weightLevel="4">BANA ÖZEL</VueText>
                  </a>
                </li>
                <li @click="activeList = 'messages'">
                  <a :class="activeList !== 'messages' ? 'notActive' : 'active'">
                    <VueText sizeLevel="4" weightLevel="4">MESAJLAR</VueText>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <VueWrapperNarrow>
        <div v-if="activeList == 'messages'" class="messages-wrapper">
          <div ref="list" class="notifications-wrapper-list" id="messageList">
            <ul class="notification-list">
              <li class="notification-list-item" v-for="(item, index) in messages" :key="item.id">
                <span class="notification-date">
                  <VueText sizeLevel="3" weightLevel="3">{{ item.date | parseISODate }}</VueText>
                </span>
                <span class="notification-title">
                  <VueText sizeLevel="6" weightLevel="3">{{ item.title }}</VueText>
                </span>
                <span class="notification-message">
                  <VueText sizeLevel="4" weightLevel="2">{{ item.message }}</VueText>
                </span>
                <a class="trash-icon" @click="deleteMessage(item.messageId, index)">
                  <img src="@/assets/icons/trash-icon.svg" alt="Delete" />
                </a>
              </li>
            </ul>
          </div>
          <div v-if="getMore" v-show="messages && messages.length > 0" ref="loadMore"></div>
        </div>
      </VueWrapperNarrow>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { removeItemByKeyValue, maxBy } from '@/mixins/arrayUtils';
import { COMPONENT_SIZES } from '@/constants/component.constants';
import { User } from '@/services/Api/index';
import dateUtils from '@/mixins/dateUtils';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';

export default {
  name: 'Notifications',
  components: {
    VueText,
    VueWrapperNarrow,
  },
  mixins: [dateUtils],
  data() {
    return {
      deletingItemId: 0,
      activeList: 'messages',
      // revealedItems: {},
      lastId: 0,
      getMore: true,
      currentPage: 1,
      messages: [],
    };
  },
  created() {
    this.getMessages();
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  mounted() {
    if (this.getMore) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            this.currentPage = this.currentPage + 1;
            this.lastId = maxBy(this.messages, 'messageId');

            this.getMessages();
          }
        },
        { threshold: [0] },
      );
      observer.observe(this.$refs.loadMore);
    }
  },
  methods: {
    deleteMessage(id, index) {
      this.deletingItemId = id;
      Array.from(document.getElementById('messageList').children).find((el, i) => {
        if (i > index) {
          el.classList.add('item-move');
          setTimeout(() => {
            removeItemByKeyValue(this.messages, 'messageId', id);
            el.classList.remove('item-move');
          }, 530);
        } else {
          setTimeout(() => {
            removeItemByKeyValue(this.messages, 'messageId', id);
          }, 520);
        }
      });
      User.deleteUserMessage(id).then(() => {});
    },
    getMessages() {
      User.getUserMessages(this.currentPage, this.lastId).then(res => {
        if (res.data && res.data.Data && res.data.Data.messages) {
          // res.data.Data.messages.forEach(element => {
          //   this.$set(this.revealedItems, element.messageId, false);
          // });
          if (this.currentPage == 1) {
            this.messages = res.data.Data.messages;
          } else {
            this.messages.push(...res.data.Data.messages);
          }

          if (!this.messages || this.messages.length == 0 || res.data.Data.messages.length == 0) {
            this.getMore = false;
          }
        } else {
          this.getMore = false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
}
/deep/ .crumbs-holder {
  padding-left: 0;
}
.border-bottom {
  border-bottom: solid 1px #b4c2d3;
  margin-bottom: 40px;
}
ul.notification-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li.notification-list-item {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: solid 1px #b4c2d3;
    background: #fbfbfd;
    margin-bottom: 20px;
    padding: 20px;
    span {
      display: inline-block;
      width: 100%;
    }
    span.notification-date {
      margin-bottom: 5px;
      color: #3d464f;
      p {
        font-weight: normal;
      }
    }
    a.trash-icon {
      position: absolute;
      right: 20px;
      top: 50%;
      max-width: 20px;
      margin-top: -12px;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
ul.actions {
  display: flex;
  z-index: 5;
  list-style-type: none;
  li {
    width: inherit !important;
    background: palette-color(white-100);
    padding: 0;
    margin-right: 30px;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 30px;
  }
  .notActive {
    display: inline-block;
    position: relative;
    p {
      color: palette-color(grey-30);
    }
  }
  .active {
    display: inline-block;
    position: relative;
    z-index: 2;
    p {
      color: palette-color(grey-40);
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      z-index: 999;
      bottom: -12px;
      background-image: linear-gradient(271deg, #d20051, #ffa300);
    }
  }
}
</style>
